




























import Vue from "vue";

import { API_URL_MODELS } from "@/config";
import DetailView from "@/components/general/DetailView.vue";
import ModelDetailCard from "@/components/model/ModelDetailCard.vue";
import RelatedItems from "@/components/general/RelatedItems.vue";
import ExhibitionHistory from "@/components/general/ExhibitionHistory.vue";
import Collapse from "@/components/general/Collapse.vue";

export default Vue.extend({
  name: "ModelDetail",
  components: {
    DetailView,
    ModelDetailCard,
    RelatedItems,
    ExhibitionHistory,
    Collapse,
  },
  data() {
    return {
      API_URL_MODELS,
    };
  },
});
