






























































import { Model } from "@/types";
import Vue, { PropType } from "vue";

export default Vue.extend({
  name: "ModelDetailCard",
  props: {
    model: {
      type: Object as PropType<Model>,
      required: true,
    },
  },
  mounted() {
    this.isLargeDescription =
      this.$helpers.removeTags(this.model.description).length > 700;
  },
  computed: {
    cleanedLeftFields: function (): { text: string; value: string | number }[] {
      return this.leftFields.filter(
        (x: { text: string; value: string | number }) => !!x.value
      );
    },
    cleanedRightFields: function (): {
      text: string;
      value: string | number;
    }[] {
      return this.rightFields.filter(
        (x: { text: string; value: string | number }) => !!x.value
      );
    },
    cleanDescription: function (): string {
      let description = this.$helpers.removeTags(this.model.description);
      if (!description) {
        return "";
      }
      if (!this.isLargeDescription) {
        return description;
      }
      description = this.shortDescription
        ? description.substring(0, 500) + "..."
        : description;
      return description;
    },
  },
  data() {
    return {
      leftFields: [
        {
          text: "Designer",
          value: this.$helpers.getDesigners(this.model.designers),
        },
        { text: "Location", value: this.model.location_building },
        { text: "Designed", value: this.model.year },
      ],
      rightFields: [
        { text: "Dimensions", value: this.model.dimension },
        { text: "Scale", value: this.model.scale },
        { text: "Inventory", value: this.model.inventoryNumber },
      ],
      shortDescription: true,
      isLargeDescription: false,
    };
  },
});
